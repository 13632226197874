import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import axios from 'axios';
import { ref } from 'vue';

// axios.defaults.withCredentials = true;

export const useAccountStore = defineStore('account', () => {
    const jwt = useLocalStorage('jwt', null);

    const accessToken = useLocalStorage('accessToken', null);
    const refreshToken = useLocalStorage('refreshToken', null);
    // async function initializeStore() {
    //     if (accessToken) {

    //     }
    // }

    // const authorization = ref(null);
    const users = ref(null);
    const tracker = ref(null);

    // async function isAuthorization(username, password) {
    //     const res = await axios.post('https://127.0.0.1/api/token/', {
    //         username: username,
    //         password: password,
    //     })
    //         .then((response) => {
    //             jwt = response.data.access;
    //             router.push({ name: 'Dashboard' });
    //         })
    //         .catch((error) => {
    //             console.log({
    //                 title: 'Не удалось войти',
    //                 message: error.response.data.detail
    //             });
    //         })
    // }

    async function isUsers() {
        try {
            const res = await axios.get('api/v1/users/user/?format=json'); // axios автоматически добавит заголовок Authorization
            users.value = res.data;
            return users.value;
        } catch (error) {
            console.error("Error fetching users:", error.response?.data || error.message)
            // Обработка ошибки
        }
    }

    async function isTracker() {
        try {  // добавлен try...catch
            const res = await axios.get('api/v1/tracker_data/trackerdata/?format=datatables'); // добавлен length для пагинации
            tracker.value = res.data;
            return tracker.value;
        } catch (error) {
            console.error("Error fetching tracker data:", error.response?.data || error.message)

            // Обработка ошибки.
        }
    }

    return { jwt, accessToken, refreshToken, isUsers, users, isTracker, tracker }
}, { persist: true })