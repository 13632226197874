import {createApp} from 'vue'
import {createPinia} from 'pinia'

import {Quasar, Dialog} from 'quasar'
import quasarLang from 'quasar/lang/ru'
import quasarIconSet from 'quasar/icon-set/svg-fontawesome-v5'

// Import icon libraries
import '@quasar/extras/roboto-font-latin-ext/roboto-font-latin-ext.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/material-icons-round/material-icons-round.css'
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css'
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css'
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css'
import '@quasar/extras/material-symbols-sharp/material-symbols-sharp.css'
import '@quasar/extras/mdi-v7/mdi-v7.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/fontawesome-v6/fontawesome-v6.css'
import '@quasar/extras/ionicons-v4/ionicons-v4.css'
import '@quasar/extras/eva-icons/eva-icons.css'
import '@quasar/extras/themify/themify.css'
import '@quasar/extras/line-awesome/line-awesome.css'
import '@quasar/extras/bootstrap-icons/bootstrap-icons.css'

// A few examples for animations from Animate.css:
import '@quasar/extras/animate/fadeIn.css'
import '@quasar/extras/animate/fadeOut.css'

// Import Quasar css
// import 'quasar/src/css/index.sass'

// import $ from 'jquery'
// window.$ = $;

import jQuery from "jquery";
Object.assign(window, {$: jQuery, jQuery});

import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

// import $ from "jquery";
// import "jquery-ui";
// import "jquery-ui-css";


import moment from 'moment';

window.moment = moment;
window.moment.locale('ru');
// import 'moment/locale/ru'
// moment.updateLocale('ru', null)

// import moment from "moment/dist/moment"
// import ru from "moment/dist/locale/ru"
// moment.locale("ru", ru)

// import ru from "moment/locale/ru";
// moment.updateLocale("ru", ru);

// import moment from 'moment';
// import VueMoment from 'vue-moment';
// require('moment/locale/ru');
// moment.locale('ru');

// import $ from 'jquery'
// export default defineNuxtPlugin( () => {
//     window.jQuery = window.$ = $
// } )

// import VueDatePicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'


import axios from 'axios'

axios.defaults.baseURL = import.meta.env.VITE_SERVER_URL
// axios.defaults.baseURL = 'https://adm.neyrox.com:8443/';
// axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
// axios.defaults.baseURL = 'https://adm.neyrox:8443/';
// axios.defaults.xsrfCookieName = 'csrftoken';
// axios.defaults.xsrfHeaderName = 'X-CSRFToken';
// axios.defaults.withCredentials = true;
// axios.defaults.withXSRFToken = true;

import HighchartsVue from 'highcharts-vue'
import Highcharts from "highcharts";
import Stock from "highcharts/modules/stock";
import stockChart from "highcharts/modules/stock";
import exportingInit from "highcharts/modules/exporting";
import stockInit from "highcharts/modules/stock";
// import offlineExporting from "highcharts/modules/offline-exporting";
// import darkUnica from "highcharts/themes/dark-unica";
import heatmap from "highcharts/modules/stock";
import treemap from "highcharts/modules/stock";
import accessibility from "highcharts/modules/stock";
// darkUnica(Highcharts);
stockChart(Highcharts);
stockInit(Highcharts);
exportingInit(Highcharts);
// offlineExporting(Highcharts);
Stock(Highcharts);
heatmap(Highcharts);
treemap(Highcharts);
accessibility(Highcharts);

// import PrimeVue from 'primevue/config';
// import Aura from '@primevue/themes/aura';
// import Button from "primevue/button"
// import "primeflex/primeflex.css";
// import "primeicons/primeicons.css";

import App from './App.vue'
import router from './router'

// import vSelect from "vue-select";
// import "vue-select/dist/vue-select.css";

// import Select2 from 'vue3-select2-component';

// import { VueSelect } from "vue-select";
// import 'vue-select/dist/vue-select.css';

// import Select2 from 'v-select2-component';


// import VueDatePicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'

// import datePicker from 'vue-bootstrap-datetimepicker';
// // import 'eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.css';
// import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';


// import { tempusDominus } from '@eonasdan/tempus-dominus/dist/js/tempus-dominus.js';



const app = createApp(App)

// app.config.globalProperties.$moment = moment

app.use(createPinia())
app.use(router, axios)
app.use(Quasar, {
    plugins: {Dialog}, // import Quasar plugins and add here
    lang: quasarLang,
    iconSet: quasarIconSet,
})
app.use(HighchartsVue, {
    highcharts: Highcharts,
    tagName: 'charts'
});
// app.component("v-select", vSelect);
// app.component('Select2', Select2)
// app.use(moment)
// app.component('VueDatePicker', VueDatePicker);
// app.use(datePicker);
// app.use(tempusDominus);
// app.use(PrimeVue, {
//     theme: {
//         preset: Aura,
//         options: {
//             prefix: 'p',
//             darkModeSelector: 'system',
//             cssLayer: false
//         }
//     }
// })
// app.component('Button', Button);
// app.use(VueMoment, { moment });
// app.use(require('vue-moment'));
// app.config.globalProperties.$moment = moment;
// app.prototype.moment = moment

// app.component('v-select', VueSelect)
// app.component('Select2', Select2)

app.mount('#app')
